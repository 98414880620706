import React, { useMemo, useState, useEffect, Fragment } from "react";
import { TextField, Box } from "@mui/material";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Padding } from "@mui/icons-material";
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";

const DateTimeTentativePickerComponent = ({
    label,
    tentative,
    allDay,
    numberOfWeeks,
    dateNeeded,
    setNumberOfWeeks,
    setDateNeeded,
    error,
    setError,
    customLabel = "Date Needed*",
    customId = "date_needed_input",
    errorKey,
    isFieldRequired = true,
    enablePastDate = false,
    minDate = null,
}) => {
    const theme = createTheme({
        components: {
            MuiMultiSectionDigitalClock: {
                styleOverrides: {
                    root: {
                        "& .MuiList-root": {
                            "&::-webkit-scrollbar": {
                                display: "none",
                            },
                            "-ms-overflow-style": "none",
                            "scrollbar-width": "none",
                            border: "none",
                            height: "225px",
                            marginTop: "5px",
                            maxWidth: "40px",
                            "& .MuiMultiSectionDigitalClockSection-item": {
                                maxWidth: "30px",
                                maxHeight: "30px",
                                fontSize: "14px",
                            },
                            "&:after": {
                                height: "calc(100% - 30px - 4px)",
                            },
                        },
                    },
                },
            },
            MuiDivider: {
                styleOverrides: {
                    root: {
                        display: "none",
                    },
                },
            },
            MuiDateCalendar: {
                styleOverrides: {
                    root: {
                        height: "225px",
                        maxWidth: "250px",
                    },
                },
            },
            MuiDialogActions: {
                styleOverrides: {
                    root: {
                        height: "36px",
                        width: "255px",
                        paddingTop: 0,
                        paddingBottom: 0,
                        paddingLeft: 0,
                        justifyContent: "space-between",
                        "& .MuiButton-text": {
                            fontSize: "11px",
                        },
                    },
                },
            },
            MuiPickersDay: {
                styleOverrides: {
                    root: {
                        width: "30px",
                        height: "30px",
                    },
                },
            },
            MuiDayCalendar: {
                styleOverrides: {
                    root: {
                        "& .MuiDayCalendar-header": {
                            justifyContent: "flex-start",
                            marginLeft: "5px",
                        },
                        "& .MuiDayCalendar-weekDayLabel": {
                            width: "30px",
                            height: "30px",
                            fontWeight: "bold",
                        },
                    },
                },
            },
            MuiPickersSlideTransition: {
                styleOverrides: {
                    root: {
                        "& .MuiDayCalendar-weekContainer": {
                            justifyContent: "flex-start",
                            marginLeft: "5px",
                        },
                    },
                },
            },
            MuiPickersCalendarHeader: {
                styleOverrides: {
                    root: {
                        paddingLeft: "10px",
                        paddingRight: 0,
                        margin: 0,
                        marginTop: "5px",
                    },
                },
            },
            MuiPickersYear: {
                styleOverrides: {
                    root: {
                        maxWidth: "50px",
                    },
                },
            },
            MuiYearCalendar: {
                styleOverrides: {
                    root: {
                        maxWidth: "240px",
                        justifyContent: "center",
                    },
                },
            },
            MuiMonthCalendar: {
                styleOverrides: {
                    root: {
                        maxWidth: "240px",
                        justifyContent: "center",
                    },
                },
            },
            MuiPickersMonth: {
                styleOverrides: {
                    root: {
                        maxWidth: "50px",
                    },
                },
            },
            MuiPickersFadeTransitionGroup: {
                styleOverrides: {
                    root: {
                        "& .MuiPickersCalendarHeader-label": {
                            fontSize: "15.5px",
                        },
                    },
                },
            },
        },
    });
    const getMinDate = () => {
        if (enablePastDate) {
            return dayjs("2010-01-01");
        }

        if (minDate) {
            return minDate;
        }
        return dayjs();
    };
    const formatLabel = () => {
        if (isFieldRequired) {
            return (
                <Box>
                    {label || "Date Needed"}
                    <span
                        style={{
                            color: "red",
                        }}
                    >
                        *
                    </span>
                </Box>
            );
        } else {
            return <>{label || "Date Needed"}</>;
        }
    };
    const formatTentativeLabel = () => {
        if (isFieldRequired) {
            return (
                <Box>
                    {label || "Number of Week(s)"}
                    <span
                        style={{
                            color: "red",
                        }}
                    >
                        *
                    </span>
                </Box>
            );
        } else {
            return <>{label || "Number of Week(s)"}</>;
        }
    };
    const [openDateTime, setOpenDateTime] = useState(false);
    const [openDate, setOpenDate] = useState(false);
    if (tentative === "yes") {
        return (
            <TextField
                id="num_weeks_input"
                size="small"
                label={formatTentativeLabel()}
                fullWidth
                value={numberOfWeeks}
                inputProps={{
                    style: { fontSize: 14 },
                    type: "number",
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    min: 1,
                    onKeyDown: (e) => {
                        if (["e", "E", "+", "-", "."].includes(e.key)) {
                            e.preventDefault();
                        }
                    },
                }}
                onChange={(event) => {
                    setNumberOfWeeks(event.target.value);
                    setError((prevError) => ({
                        ...prevError,
                        noOfWeeks: null,
                    }));
                }}
                error={Boolean(error.noOfWeeks)}
                helperText={error.noOfWeeks}
                sx={{
                    height: "37.13px",
                    "& .MuiInputBase-root": {
                        height: "100%",
                    },
                    "& .MuiOutlinedInput-input": {
                        height: "100%",
                        boxSizing: "border-box",
                    },
                }}
            />
        );
    }

    if (allDay === "yes") {
        return (
            <DatePicker
                views={["year", "month", "day"]}
                label={formatLabel()}
                id={customId}
                minDate={getMinDate()}
                maxDate={dayjs().add(5, "years")}
                value={dateNeeded}
                onChange={(newValue) => {
                    setDateNeeded(newValue);
                    setError((prevError) => ({
                        ...prevError,
                        [errorKey || "date_needed"]: null,
                    }));
                }}
                onOpen={() => setOpenDate(true)}
                onClose={() => setOpenDate(false)}
                open={openDate}
                slotProps={{
                    textField: {
                        error: Boolean(error[errorKey || "date_needed"]),
                        helperText: error[errorKey || "date_needed"],
                        fullWidth: true,
                        size: "small",
                        inputProps: {
                            style: { fontSize: 14 },
                            onClick: () => setOpenDate(true),
                        },
                    },
                }}
            />
        );
    }

    if (allDay === "no") {
        return (
            <ThemeProvider theme={theme}>
                <DesktopDateTimePicker
                    views={["year", "month", "day", "hours", "minutes"]}
                    label={formatLabel()}
                    id="date_needed_input"
                    value={dateNeeded}
                    minDate={getMinDate()}
                    maxDate={dayjs().add(5, "years")}
                    onChange={(newValue) => {
                        setDateNeeded(newValue);
                        setError((prevError) => ({
                            ...prevError,
                            [errorKey || "date_needed"]: null,
                        }));
                    }}
                    onOpen={() => setOpenDateTime(true)}
                    onClose={() => setOpenDateTime(false)}
                    open={openDateTime}
                    slotProps={{
                        textField: {
                            error: Boolean(error[errorKey || "date_needed"]),
                            helperText: error[errorKey || "date_needed"],
                            fullWidth: true,
                            size: "small",
                            inputProps: {
                                style: { fontSize: 14 },
                                onClick: () => setOpenDateTime(true),
                            },
                        },
                        actionBar: {
                            actions: ["clear", "today"],
                        },
                    }}
                />
            </ThemeProvider>
        );
    }

    return null;
};

export default DateTimeTentativePickerComponent;
