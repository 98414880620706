import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import LoadingButton from "@mui/lab/LoadingButton";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DefaultButton from "./DefaultButton";
import { Box } from "@mui/material";
import { Divider } from "@mui/material";
export default function PromptDialog({
    title = "",
    message = "",
    renderForm = null,
    show = false,
    onUserResponse,
    variant = "prompt",
    titleDivider = false,
    onDialogClose = () => {},
    customWidth = null,
    customHeight = null,
    enableOverFlowY = false,
    customFormZIndex = null,
}) {
    const [open, setOpen] = React.useState(false);
    const [isDisabled, setIsDIsabled] = React.useState(false);

    React.useEffect(() => {
        if (show) {
            setOpen(true);
            setIsDIsabled(false);
        } else {
            setOpen(false);
        }
    }, [show]);

    React.useEffect(() => {
        if (show) {
            setOpen(true);
            setIsDIsabled(false);
        } else {
            setOpen(false);
        }
    }, [show]);

    const handleClose = () => {
        setOpen(false);
        onDialogClose();
    };

    return (
        <div>
            {variant == "form" && (
                <Dialog
                    open={open}
                    fullWidth
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    PaperProps={{
                        sx: {
                            overflowY: "visible",
                            maxWidth: customWidth ? customWidth : "600px",
                            maxHeight: customHeight ?? "600px",
                        },
                    }}
                    sx={{
                        zIndex: customFormZIndex
                            ? customFormZIndex
                            : "999 !important",
                    }}
                >
                    <DialogTitle
                        id="alert-dialog-title"
                        sx={{
                            fontSize: 18,
                            fontWeight: "bold",
                            borderBottom: titleDivider
                                ? "1px solid #e0e0e0"
                                : "",
                        }}
                    >
                        {title}
                    </DialogTitle>
                    <Box
                        sx={{
                            marginX: 3,
                            marginBottom: 2,
                        }}
                    >
                        {renderForm()}
                    </Box>
                </Dialog>
            )}
            {variant == "prompt" && (
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    sx={{
                        zIndex: "999 !important",
                    }}
                >
                    <DialogTitle
                        id="alert-dialog-title"
                        sx={{
                            fontSize: 13,
                            fontWeight: "bold",
                        }}
                    >
                        {title}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText
                            id="alert-dialog-description"
                            sx={{
                                fontSize: 13,
                                color: "#000000",
                            }}
                        >
                            {message}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <DefaultButton
                            id="prompt-dialog-cancel"
                            label="Cancel"
                            variant="outlined"
                            onClick={() => {
                                onUserResponse(false);
                            }}
                        />
                        <DefaultButton
                            id="prompt-dialog-yes"
                            label="Yes"
                            isLoading={isDisabled}
                            onClick={() => {
                                setIsDIsabled(true);
                                onUserResponse(true);
                            }}
                        />
                    </DialogActions>
                </Dialog>
            )}
            {variant == "alert" && (
                <Dialog
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        style: {
                            width: "600px",
                        },
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    sx={{
                        zIndex: "100000 !important",
                    }}
                >
                    <DialogTitle
                        id="alert-dialog-title"
                        sx={{
                            fontWeight: "bold",
                        }}
                    >
                        {title}
                    </DialogTitle>
                    {message != "" && (
                        <DialogContent>
                            <DialogContentText
                                id="alert-dialog-description"
                                sx={{
                                    fontSize: 13,
                                    color: "#000000",
                                }}
                            >
                                {message}
                            </DialogContentText>
                        </DialogContent>
                    )}
                    <DialogActions>
                        <DefaultButton
                            id="alert-dialog-ok"
                            label="OK"
                            variant="contained"
                            onClick={() => {
                                onUserResponse(false);
                            }}
                        />
                    </DialogActions>
                </Dialog>
            )}
            {variant == "qr" && (
                <Dialog
                    open={open}
                    fullWidth={"xl"}
                    maxWidth={true}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    PaperProps={{
                        sx: {
                            overflowY: "visible",
                        },
                    }}
                    sx={{
                        zIndex: "99999 !important",
                    }}
                >
                    <DialogTitle
                        id="alert-dialog-title"
                        sx={{
                            fontSize: 18,
                            fontWeight: "bold",
                        }}
                    >
                        {title}
                    </DialogTitle>
                    <Box
                        sx={{
                            marginX: 3,
                            marginBottom: 2,
                        }}
                    >
                        {renderForm()}
                    </Box>
                    <DialogActions>
                        <DefaultButton
                            id="qr-dialog-close"
                            label="Close"
                            variant="contained"
                            onClick={() => {
                                onUserResponse(false);
                            }}
                        />
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
}
