import React, { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Loading from "./Loading";
const MainSnackbar = ({
    severity = "success",
    loading,
    message,
    action = null,
    open,
    onClose,
    closeButton = true,
    autoHideDuration = 6000,
    icon,
    variant = "standard",
    ...props
}) => {
    const [openSnackbar, setOpenSnackbar] = useState(open);

    const Action = action;

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
        onClose();
    };

    const actions = loading ? (
        <Loading size={25} />
    ) : (
        <React.Fragment>
            {Action ? <Action /> : null}
            {closeButton ? (
                <IconButton
                    aria-label="close"
                    color="inherit"
                    sx={{ p: 0.5 }}
                    onClick={handleClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </React.Fragment>
    );

    useEffect(() => {
        setOpenSnackbar(open);
    }, [open]);

    return (
        <Box sx={{ boxShadow: 1 }}>
            <Stack
                spacing={2}
                sx={{
                    width: "100%",
                    zIndex: 100000000000,
                    position: "absolute",
                }}
            >
                <Snackbar
                    open={openSnackbar}
                    autoHideDuration={loading ? undefined : autoHideDuration}
                    onClose={handleClose}
                    {...props}
                >
                    <Alert
                        severity={severity}
                        action={actions}
                        icon={icon}
                        variant={variant}
                    >
                        {message}
                    </Alert>
                </Snackbar>
            </Stack>
        </Box>
    );
};

export default MainSnackbar;
