import React, { useState, useEffect } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import PropTypes from 'prop-types';

export default function DefaultCheckButton({
    checkedLabel = 'Checked',
    uncheckedLabel = 'Unchecked',
    isChecked = false,
    name = '',
    id = '',
    onCheckChange = () => {},
    isLoading = false,
    checkedButtonColor = 'success',
    uncheckedButtonColor = 'primary',
    fontSize = '0.875rem', 
    variant: externalVariant,
    ...props
}) {
    const [isButtonChecked, setButtonChecked] = useState(isChecked);

    useEffect(() => {
        setButtonChecked(isChecked);
    }, [isChecked]);

    const handleButtonClick = () => {
        setButtonChecked((prevState) => {
            const newState = !prevState;
            onCheckChange(newState);
            return newState;
        });
    };

    const label = isButtonChecked ? checkedLabel : uncheckedLabel;
    const variant = externalVariant || (isButtonChecked ? 'contained' : 'outlined');

    return (
        <LoadingButton
            {...props}
            size="small"
            loading={isLoading}
            variant={variant}
            onClick={handleButtonClick}
            color={isButtonChecked ? checkedButtonColor : uncheckedButtonColor}
            aria-pressed={isButtonChecked}
        >
            <span style={{ fontSize, textTransform: 'none' }}>
                {label}
            </span>
        </LoadingButton>
    );
}

DefaultCheckButton.propTypes = {
    checkedLabel: PropTypes.string,
    uncheckedLabel: PropTypes.string,
    isChecked: PropTypes.bool,
    name: PropTypes.string,
    id: PropTypes.string,
    onCheckChange: PropTypes.func,
    isLoading: PropTypes.bool,
    checkedButtonColor: PropTypes.string,
    uncheckedButtonColor: PropTypes.string,
    variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
};
