import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import {
    Paper,
    Button,
    Stack,
    Slide,
    Typography,
    Grid,
    Divider,
    TextField,
    CircularProgress,
    Box,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
    Hidden,
} from "@mui/material";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { fromPairs, isSet, size } from "lodash";
import { index } from "d3-array";
import moment from "moment/moment";
import { object } from "prop-types";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";

export default function OppositeContentTimeline({
    auditData = [],
    auditField = [],
    workflowDetails = [],
    customField = [],
    withWorkflow,
}) {
    console.log("auditsDetails:" + JSON.stringify(auditData));
    console.log("auditsField:" + JSON.stringify(auditField));
    console.log("workflowDetails:" + JSON.stringify(workflowDetails));
    console.log("withWorkflow:" + withWorkflow);
    console.log("customField:" + JSON.stringify(customField));

    function timeStampSlice(timestamp) {
        const endIndex = timestamp.indexOf("(") - 1;
        const slicedTimestamp = timestamp.substring(0, endIndex).trim();
        const timeBefore = timestamp
            .substring(timestamp.indexOf("(") + 1, timestamp.indexOf(")"))
            .trim()
            .split(" ")
            .slice(0, 2)
            .join(" ");

        return (
            <>
                <span style={{ fontWeight: "bold" }}>{timeBefore} ago</span>
                <br />
                <span>{slicedTimestamp}</span>
            </>
        );
    }

    function checkSessionUser(sessionUserID, retrievedUserID, userName) {
        if (sessionUserID == retrievedUserID) {
            return "You";
        } else {
            return userName;
        }
    }

    function customFieldFormatter(arr, customKey, keyword) {
        if (Object.keys(arr).includes(customKey)) {
            const mappedArr = arr[customKey].map((item, z) => {
                console.log("z:", z);
                console.log(
                    "arr[customKey] lenght - 1:",
                    arr[customKey].length - 1
                );
                return (
                    <span key={z}>
                        {keyword} {item}
                        {z === arr[customKey].length - 1 ? "." : ","}
                        <br />
                    </span>
                );
            });
            return mappedArr;
        }
    }

    function fieldFormatter(audit, fields, customFields) {
        const auditArray = [audit];
        // console.log("fields:", JSON.stringify(fields));
        console.log("auditArray:", JSON.stringify(auditArray));

        const mappedText = auditArray.map((auditArr) =>
            fields.map((field, x) => {
                console.log("//////////////////////////////////");
                console.log("field.isCustomField:", field.isCustomField);
                console.log("field.ColumnName:", field.columnName);
                if (
                    Object.keys(auditArr.modifiedValues.old).includes(
                        field.columnName
                    ) &&
                    field.isCustomField == false
                ) {
                    console.log("-------------------------");
                    console.log("isCustom Field is false");
                    if (field.isBoolean) {
                        switch (auditArr.modifiedValues.old[field.columnName]) {
                            case 0:
                                return (
                                    <span key={x}>
                                        <span
                                            style={{
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {field.fieldName}
                                        </span>{" "}
                                        from{" "}
                                        <span
                                            style={{
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {field.ifFalse}
                                        </span>{" "}
                                        to{" "}
                                        <span
                                            style={{
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {field.ifTrue}
                                        </span>
                                        {x === fields.length - 1 ? "." : ","}
                                        <br />
                                    </span>
                                );
                            case 1:
                                return (
                                    <span key={x}>
                                        <span
                                            style={{
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {field.fieldName}
                                        </span>{" "}
                                        from{" "}
                                        <span
                                            style={{
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {field.ifTrue}
                                        </span>{" "}
                                        to{" "}
                                        <span
                                            style={{
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {field.ifFalse}
                                        </span>
                                        {x === fields.length - 1 ? "." : ","}
                                        <br />
                                    </span>
                                );
                            default:
                                break;
                        }
                    } else {
                        return (
                            <span key={x}>
                                <span
                                    style={{
                                        fontWeight: "bold",
                                    }}
                                >
                                    {field.fieldName}
                                </span>{" "}
                                from{" "}
                                <span
                                    style={{
                                        fontWeight: "bold",
                                    }}
                                >
                                    {
                                        auditArr.modifiedValues.old[
                                            field.columnName
                                        ]
                                    }
                                </span>{" "}
                                to{" "}
                                <span
                                    style={{
                                        fontWeight: "bold",
                                    }}
                                >
                                    {
                                        auditArr.modifiedValues.new[
                                            field.columnName
                                        ]
                                    }
                                </span>
                                {x === fields.length - 1 ? "." : ","}
                                <br />
                            </span>
                        );
                    }
                }
                if (
                    Object.keys(auditArr.modifiedValues.old).includes(
                        field.columnName
                    ) &&
                    field.isCustomField == true
                ) {
                    console.log("-------------------------");
                    console.log("isCustom Field is true");
                    const nameOfField = field.fieldName;
                    const mappedCustomField = customFields.map(
                        (customField, y) => {
                            if (customField.columnName == field.columnName) {
                                console.log(
                                    "customField keyword:",
                                    customField.keyword
                                );
                                const formattedCustomField =
                                    customFieldFormatter(
                                        auditArr,
                                        customField.customKey,
                                        customField.keyword
                                    );
                                return (
                                    <span
                                        key={y}
                                        style={{
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {formattedCustomField}
                                    </span>
                                );
                            }
                            return null;
                        }
                    );
                    return (
                        <span
                            key={x}
                            style={{
                                fontWeight: "bold",
                            }}
                        >
                            {nameOfField}
                            {": "}
                            {mappedCustomField}
                        </span>
                    );
                }
                return null;
            })
        );

        return <span>{mappedText}</span>;
    }

    function btnFormatter(type) {
        if (type == "btn-secondary") {
            return "#7c868e";
        }
        if (type == "btn-danger") {
            return "#d33f49";
        }
        if (type == "btn-success") {
            return "#3ca648";
        }
        if (type == "btn-primary") {
            return "#3a79fc";
        }
    }

    //audits history
    const renderedItem = auditData.map((item) => {
        const timestamp = timeStampSlice(item.timestamp);
        const sessionUser = checkSessionUser(
            item.sessionID,
            item.userId,
            item.user
        );
        if (item.modifiedValues.old != "" && item.modifiedValues.old != null) {
            // console.log("old data not empty");
            return (
                <TimelineItem key={item}>
                    <TimelineSeparator>
                        <TimelineDot variant="outlined" color="primary" />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent style={{ fontSize: "12px" }}>
                        <span>
                            <span>{timestamp}</span>
                            <br />
                            <br />
                            <span style={{ fontWeight: "bold" }}>
                                {sessionUser}
                            </span>
                            <span>
                                {" "}
                                {item.event}{" "}
                                {fieldFormatter(item, auditField, customField)}
                            </span>
                        </span>
                    </TimelineContent>
                </TimelineItem>
            );
        } else {
            // console.log("old data empty");
            return (
                <TimelineItem key={item}>
                    <TimelineSeparator>
                        <TimelineDot variant="outlined" color="primary" />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent style={{ fontSize: "12px" }}>
                        <span>
                            <span>{timestamp}</span>
                            <br />
                            <br />
                            {item.event} by{" "}
                            <span style={{ fontWeight: "bold" }}>
                                {sessionUser}
                            </span>
                        </span>
                    </TimelineContent>
                </TimelineItem>
            );
        }
    });

    //workflow
    const renderedWorkflow = workflowDetails.map((item) => {
        const timestamp = timeStampSlice(item.timestamp);
        const sessionUser = checkSessionUser(
            item.sessionID,
            item.userId,
            item.user
        );
        // console.log("from_type:", item.from_type);
        // console.log("to_type:", item.to_type);
        if (item.from != "" && item.from != null) {
            return (
                <TimelineItem key={item}>
                    <TimelineSeparator>
                        <TimelineDot variant="outlined" color="primary" />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent style={{ fontSize: "12px" }}>
                        <span>
                            <span>{timestamp}</span>
                            <br />
                            <br />
                            <span style={{ fontWeight: "bold" }}>
                                {sessionUser}
                            </span>
                            <span> changed the ticket status</span>
                            <br />
                            <Button
                                sx={{
                                    pl: "5px",
                                    pt: "3px",
                                    pb: "1px",
                                    pr: "5px",
                                    minWidth: 0,
                                    fontSize: "9px",
                                    textAlign: "center",
                                    borderRadius: "10px",
                                    color: "white",
                                    backgroundColor: btnFormatter(
                                        item.from_type
                                    ),
                                    cursor: "default",
                                    "&:hover": {
                                        backgroundColor: btnFormatter(
                                            item.from_type
                                        ),
                                        cursor: "default",
                                    },
                                }}
                            >
                                {item.from}
                            </Button>
                            <ArrowForwardIcon fontSize="small" />
                            <Button
                                sx={{
                                    pl: "5px",
                                    pt: "3px",
                                    pb: "1px",
                                    pr: "5px",
                                    minWidth: 0,
                                    fontSize: "9px",
                                    textAlign: "center",
                                    borderRadius: "10px",
                                    color: "white",
                                    backgroundColor: btnFormatter(item.to_type),
                                    cursor: "default",
                                    "&:hover": {
                                        backgroundColor: btnFormatter(
                                            item.to_type
                                        ),
                                        cursor: "default",
                                    },
                                }}
                            >
                                {item.to}
                            </Button>
                            <br />
                            {item.remark !== null ? (
                                <>
                                    <span style={{ fontWeight: "bold" }}>
                                        Remark:
                                    </span>{" "}
                                    {item.remark}
                                </>
                            ) : null}
                        </span>
                    </TimelineContent>
                </TimelineItem>
            );
        }
        if (workflowDetails.length === 1) {
            return (
                <Grid item xs="auto">
                    <LibraryBooksIcon fontSize="small" /> No Workflow History
                    yet
                </Grid>
            );
        }
    });

    useEffect(() => {
        console.log("******************************");
        console.log("workflow array length:", workflowDetails.length);
        console.log("audit array length:", auditData.length);
    }, [auditData, workflowDetails]);

    return (
        <Grid
            container
            spacing={2}
            justifyContent="space-around"
            alignItems="flex-start"
        >
            <Grid item md="auto" xs={12}>
                <Paper
                    sx={{
                        p: 2.5,
                    }}
                >
                    <Typography variant="h6" gutterBottom>
                        History
                    </Typography>
                    <Timeline
                        reverse={true}
                        sx={{
                            [`& .${timelineItemClasses.root}:before`]: {
                                flex: 0,
                                padding: 0,
                            },
                            display: "flex",
                            flexDirection: "column-reverse",
                        }}
                    >
                        {auditData.length === 0 ? (
                            <Grid item xs="auto">
                                <LibraryBooksIcon fontSize="small" /> No History
                                yet
                            </Grid>
                        ) : (
                            renderedItem
                        )}
                    </Timeline>
                </Paper>
            </Grid>
            {withWorkflow == true ? (
                <Grid item md="auto" xs={12}>
                    <Paper
                        sx={{
                            p: 2.5,
                        }}
                    >
                        <Typography variant="h6" gutterBottom>
                            Workflow history
                        </Typography>
                        <Timeline
                            reverse={true}
                            sx={{
                                [`& .${timelineItemClasses.root}:before`]: {
                                    flex: 0,
                                    padding: 0,
                                },
                                display: "flex",
                                flexDirection: "column-reverse",
                            }}
                        >
                            {workflowDetails.length === 0 ? (
                                <Grid item xs="auto">
                                    <LibraryBooksIcon fontSize="small" /> No
                                    Workflow History yet
                                </Grid>
                            ) : (
                                renderedWorkflow
                            )}
                        </Timeline>
                    </Paper>
                </Grid>
            ) : (
                <Grid item xs="auto"></Grid>
            )}
        </Grid>
    );
}
