import MaterialReactTable from "material-react-table";
import React, { useState, useEffect, useRef, Fragment, useMemo } from "react";
import ReactDOM from "react-dom";
import {
    MRT_ToggleFiltersButton,
    MRT_ShowHideColumnsButton,
    MRT_ToggleDensePaddingButton,
} from "material-react-table";
import {
    IconButton,
    Tooltip,
    Chip,
    Button,
    Box,
    Grid,
    Stack,
    CircularProgress,
} from "@mui/material";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import Client from "../../components/Client";

export default function IndexTable({
    rows,
    columns,
    hiddenColumns = [],
    isLoading = false,
    defaultDensity = "compact",
    canToggleColumnsVisibility = true,
    showTopToolbar = true,
    max_height = "2000px",
    showColumnOrdering = false,
    hideRefreshButton = true,
    disableSavingColumnArrangement = true,
    url = "#",
    ...props
}) {
    const client = Client();
    const [resetBtn, setResetBtn] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);
    const [columnOrder, setColumnOrder] = useState(() =>
        columns.map((col) => col.accessorKey)
    );

    const memoizedColumns = useMemo(
        () =>
            columns.map((col) => ({
                ...col,
                accessorKey: col.accessorKey || col.id,
            })),
        [columns]
    );

    // Load saved column arrangement
    useEffect(() => {
        if (!disableSavingColumnArrangement) {
            const loadSavedArrangement = async () => {
                try {
                    setTableLoading(true);
                    const response = await client.post(
                        "/cis/index_query_table/show",
                        {
                            screen: url,
                        }
                    );

                    if (response.data?.column_arrangement?.length > 0) {
                        setColumnOrder(response.data.column_arrangement);
                        setTableLoading(false);
                    }
                } catch (error) {
                    setTableLoading(false);
                    console.error("Error loading column arrangement:", error);
                }
            };

            loadSavedArrangement();
        }
    }, [url, disableSavingColumnArrangement]);

    // Save column arrangement when it changes
    const handleColumnOrderChange = async (newOrder) => {
        setColumnOrder(newOrder);

        if (!disableSavingColumnArrangement) {
            try {
                await client.post("/cis/index_query_table/store", {
                    screen: url,
                    column_arrangement: newOrder,
                    hidden_columns: hiddenColumns,
                    sorting: [],
                });
            } catch (error) {
                console.error("Error saving column arrangement:", error);
            }
        }
    };

    // Reset table to default arrangement
    const resetTable = async (tableInstance) => {
        const defaultOrder = memoizedColumns.map((col) => col.accessorKey);
        setResetBtn(true);

        try {
            setColumnOrder(defaultOrder);
            tableInstance.setColumnOrder(defaultOrder);

            if (!disableSavingColumnArrangement) {
                await client.post("/cis/index_query_table/store", {
                    screen: url,
                    column_arrangement: defaultOrder,
                    hidden_columns: hiddenColumns,
                    sorting: [],
                });
            }
        } catch (error) {
            console.error("Error resetting table:", error);
        } finally {
            setResetBtn(false);
        }
    };

    useEffect(() => {
        console.log("tableLoading:", tableLoading);
    }, [tableLoading]);

    const hideColumns = {};
    hiddenColumns != []
        ? hiddenColumns.map((item) => {
              hideColumns[item] = false;
          })
        : null;

    return (
        <MaterialReactTable
            columns={memoizedColumns}
            enableStickyHeader={true}
            enableColumnActions={false}
            enableTopToolbar={showTopToolbar}
            enableHiding={canToggleColumnsVisibility}
            enableColumnFilters={true}
            enableFullScreenToggle={false}
            onColumnOrderChange={handleColumnOrderChange}
            enableColumnDragging={showColumnOrdering}
            enableColumnOrdering={showColumnOrdering}
            rowsPerPageOptions={[20, 50, 100]}
            data={rows}
            {...props}
            initialState={{
                density: defaultDensity,
                columnVisibility: hideColumns,
                showGlobalFilter: true,
                pagination: {
                    pageSize: 20,
                },
                showGlobalFilter: true,
            }}
            state={{
                showProgressBars: isLoading,
                isLoading: tableLoading,
                columnOrder,
                // columnVisibility: hideColumns,
            }}
            muiSearchTextFieldProps={{
                variant: "standard",
                inputProps: {
                    sx: {
                        backgroundColor: "#FFFFFF !important",
                    },
                    id: "global_filter_field",
                },
            }}
            muiTablePaperProps={{
                sx: {
                    boxShadow: "none",
                    // paddingTop: "40px",
                    ".MuiTypography-root": {
                        maxWidth: "100vw",
                    },
                },
            }}
            muiTableContainerProps={{
                sx: {
                    maxHeight: max_height, // Set max height here
                    overflow: "auto", // Enable scrolling
                },
            }}
            muiTableHeadCellProps={{
                sx: {
                    borderBottom: "2px solid black",
                    fontSize: "12px",
                    textAlign: "left",
                    verticalAlign: "middle",
                    // "& .Mui-TableHeadCell-Content": {
                    //     display: "flex",
                    //     justifyContent: 'left',
                    //     paddingLeft: 2
                    // },
                    'button:has(svg[data-testid="FilterAltIcon"])': {
                        display: "none",
                        pointerEvents: "none",
                    },
                    // 'span:has(svg[data-testid="ArrowDownwardIcon"])': {
                    //     display: "none",
                    //     pointerEvents: "none",
                    // },
                    // ".Mui-TableHeadCell-Content-Labels": {
                    //     pointerEvents: "none",
                    // },
                },
            }}
            muiTableBodyCellProps={{
                sx: {
                    fontSize: "12px",
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                    p: 1,
                    paddingLeft: "16px",
                },
            }}
            muiTablePaginationProps={{
                rowsPerPageOptions: [20, 50, 100],
                sx: {
                    fontSize: "12px",
                    textAlign: "left",
                    "& .MuiTablePagination-selectLabel": {
                        fontSize: "12px",
                        margin: 0,
                        display: "flex",
                        alignItems: "left",
                    },
                    "& .MuiTablePagination-displayedRows": {
                        fontSize: "12px",
                        margin: 0,
                        display: "flex",
                        alignItems: "left",
                    },
                    "& .MuiPopover-paper": {
                        backgroundColor: "red",
                        "& .MuiMenu-list": {
                            "& .MuiTablePagination-menuItem": {
                                fontSize: "12px",
                                color: "red",
                            },
                        },
                    },
                    overflowX: "hidden",
                    "@media (max-width: 425px)": {
                        "& .MuiTablePagination-toolbar": {
                            textAlign: "right",
                            display: "inline-block",
                        },
                        "& .MuiTablePagination-selectLabel": {
                            display: "inline",
                        },
                        "& .MuiTablePagination-actions": {
                            marginLeft: "110px",
                        },
                        "& .MuiTablePagination-displayedRows": {
                            display: "inline",
                            marginBottom: "0",
                        },
                    },
                },
            }}
            renderToolbarInternalActions={({ table }) => (
                <Box display="flex" alignItems="center">
                    <MRT_ToggleFiltersButton table={table} />
                    <MRT_ShowHideColumnsButton table={table} />
                    {!hideRefreshButton ? (
                        <Tooltip title="Reset default table">
                            <IconButton
                                disabled={resetBtn}
                                onClick={() => resetTable(table)}
                            >
                                <RestartAltIcon />
                            </IconButton>
                        </Tooltip>
                    ) : null}
                </Box>
            )}
        />
    );
}
